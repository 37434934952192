import React from 'react';
import { Checkbox, TableCell, TableRow } from '@mui/material';

import { Text } from 'src/components/shared/ui';
import { GeneralDataType } from 'src/interfaces';
import { useAppSelector } from 'src/redux';
import { RootReducer } from 'src/redux/modules/types';
import { wrapName } from 'src/utils/formatters';

import { CustomTableRowProps, HeadCell } from '../../types';
import ActionItems from '../action-items';
import styles from './index.module.css';

const CustomTableRow = <DataType extends GeneralDataType>({
  headCells,
  row,
  isItemSelected,
  checkboxes,
  deleteIcon,
  editIcon,
  duplicateIcon,
  customIconText,
  handleEdit,
  handleDelete,
  handleDuplicate,
  handleCustomIcon,
  style,
  saveEditableText,
  handleObjectCheckboxClick,
  index,
  linkIcon,
  handleLinkIcon,
  isLoading,
  handleOnRowClick,
  cellContentLength,
  maxCellContentLength,
}: CustomTableRowProps<DataType>): JSX.Element => {
  let disableDeleteIcon = false;
  const { authenticated } = useAppSelector((state: RootReducer) => state.auth);

  return (
    <TableRow
      style={style}
      data-testid={`row-${index}`}
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
      className={handleOnRowClick ? styles.mousePointer : ''}
    >
      {checkboxes && (
        <TableCell className={styles.tablePaddingLeftCheckbox}>
          <Checkbox
            color="primary"
            checked={isItemSelected}
            disabled={isLoading}
            onClick={() => {
              handleObjectCheckboxClick(row);
            }}
          />
        </TableCell>
      )}
      {headCells.map((headCell: HeadCell, index) => {
        const headId = headCell.id.toString();
        const headDots = headId.includes('.') ? headId.split('.') : [];
        let cellValue = headDots.length ? row : row[headCell.id];
        if (headDots.length) {
          for (let i = 0; i < headDots.length; i++) {
            if (typeof cellValue === 'string') {
              break;
            }
            if (typeof cellValue === 'object') {
              cellValue = cellValue[headDots[i]];
            }
          }
        }

        let cellElement: JSX.Element;
        if (headCell.cellElements?.length) {
          const chip = headCell.cellElements.find((cellElement) => cellElement.id === row[headId]);
          if (chip.id === 'ADMIN' && authenticated.userType === 'NORMAL') {
            disableDeleteIcon = true;
          } else {
            disableDeleteIcon = chip?.disableDeleteButton;
          }
          cellElement = chip.element;
        }
        return (
          <TableCell
            key={index}
            align={headCell.align ? headCell.align : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            colSpan={headCell?.columns}
            className={`${!checkboxes && index === 0 && styles.tablePaddingLeftCell}`}
            onClick={() => (handleOnRowClick ? handleOnRowClick(row) : '')}
          >
            {headCell.cellElements?.length ? (
              cellElement
            ) : (
              <Text data-testid={`column-${index}`}>
                {cellValue ? wrapName(cellValue, cellContentLength, maxCellContentLength) : '--'}
              </Text>
            )}
          </TableCell>
        );
      })}
      <TableCell>
        {(deleteIcon || editIcon || customIconText || duplicateIcon) && (
          <ActionItems<DataType>
            isLoading={isLoading}
            saveEditableText={saveEditableText}
            index={index}
            customIconText={customIconText}
            linkIcon={linkIcon}
            handleLinkIcon={handleLinkIcon}
            editIcon={editIcon}
            deleteIcon={deleteIcon}
            duplicateIcon={duplicateIcon}
            handleCustomIcon={handleCustomIcon}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleDuplicate={handleDuplicate}
            row={row}
            disableDeleteIcon={disableDeleteIcon}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default CustomTableRow;
