import {
  ContentErrorType,
  ModalState,
  ModuleErrorType,
  SnackbarState,
} from 'src/redux/modules/ui/types';

interface ModalParams {
  entity?: string;
  reason?: string;
  reminder?: string;
  handleConfirm?: ModalState['handleConfirm'];
  handleOnClose?: ModalState['handleOnClose'];
}
interface SnackbarParams {
  title?: string;
  message?: string;
  open?: boolean;
  severity?: 'success' | 'error';
}

export const genericError: ModalState = {
  title: 'Algo salió mal',
  description: 'Por favor reintente nuevamente.',
  type: 'alert',
};

export const invalidForm: ModalState = {
  ...genericError,
  description: 'Por favor revise los datos ingresados.',
};

export const invalidEmail: SnackbarState = {
  title: 'Mail existente',
  message: 'La dirección de mail ya está en uso por otra cuenta.',
  severity: 'error',
};

export const disabledEmail: SnackbarState = {
  title: 'Mail existente',
  message: 'La dirección de mail ya está en uso por otra cuenta que está deshabilitada.',
  severity: 'error',
};

export const cannotShowList = ({ entity }: ModalParams): ModalState => ({
  title: 'Ocurrió un error',
  description: `No se puede mostrar la lista de ${entity}, intente nuevamente.`,
  type: 'alert',
});

export const snackbarError = ({ message }: SnackbarParams): SnackbarState => ({
  title: 'Ocurrió un error',
  message: `${message}`,
  severity: 'error',
});

export const snackbarSuccess = ({ message }: SnackbarParams): SnackbarState => ({
  title: 'Éxito',
  message: `${message}`,
  severity: 'success',
});

export const confirmAdd = ({ entity, handleConfirm }: ModalParams): ModalState => ({
  title: `Agregar ${entity}`,
  description: `¿Está seguro que desea agregar a este ${entity}?`,
  type: 'confirm',
  handleConfirm,
});

export const confirmCancel = ({ handleConfirm }: ModalParams): ModalState => ({
  title: 'Cancelar',
  description: '¿Está seguro que desea cancelar? Se perderán los cambios sin guardar.',
  type: 'confirm',
  handleConfirm,
});

export const confirmGoBack = ({ handleConfirm }: ModalParams): ModalState => ({
  title: 'Volver',
  description: '¿Está seguro que desea volver atrás? Los datos en el formulario se perderán.',
  type: 'confirm',
  handleConfirm,
});

export const confirmLogOut = ({ handleConfirm }: ModalParams): ModalState => ({
  title: 'Cerrar sesión',
  description: '¿Está seguro que desea cerrar sesion?',
  type: 'confirm',
  handleConfirm,
});

export const confirmDelete = ({ entity, handleConfirm }: ModalParams): ModalState => ({
  title: `Eliminar ${entity}`,
  description: `¿Está seguro que desea eliminar este ${entity}?`,
  type: 'confirm',
  handleConfirm,
});

export const confirmEdit = ({ entity, handleConfirm }: ModalParams): ModalState => ({
  title: `Editar ${entity}`,
  description: `¿Está seguro que desea editar este ${entity}?`,
  type: 'confirm',
  handleConfirm,
});

export const alertEdit = ({ entity }: ModalParams): ModalState => ({
  title: `Editar ${entity}`,
  description: `El ${entity} se editó correctamente.`,
  type: 'alert',
});

export const cannotDoAction = ({ reason }: ModalParams): ModalState => ({
  title: 'No fue posible realizar esta acción',
  description: `${reason}`,
  type: 'alert',
});

export const cannotDoActionAndConfirm = ({ reason, handleConfirm }: ModalParams): ModalState => ({
  title: 'No fue posible realizar esta acción',
  description: `${reason}`,
  type: 'alert',
  handleConfirm,
});

export const confirmSend = ({ entity, handleConfirm }: ModalParams): ModalState => ({
  title: `Enviar ${entity}`,
  description: `¿Está seguro que desea enviar este ${entity}?`,
  type: 'confirm',
  handleConfirm,
});

export const alertSend = ({ entity, handleOnClose }: ModalParams): ModalState => ({
  title: `Enviar ${entity}`,
  description: `El ${entity} se envió correctamente.`,
  type: 'alert',
  handleOnClose,
});

export const confirmSendAnswer = ({
  entity,
  handleConfirm,
  reminder,
}: ModalParams): ModalState => ({
  title: `Enviar ${entity}`,
  description: `¿Está seguro que desea enviar esta ${entity}? ${reminder || ''}`,
  type: 'confirm',
  handleConfirm,
});

export const snackbarMessage = (
  action: 'crear' | 'editar' | 'eliminar' | 'desabilitar' | 'agregar' | 'cambiar',
  entity:
    | 'contenido'
    | 'módulo'
    | 'alumno'
    | 'formulario'
    | 'asistencia'
    | 'grupo'
    | 'nombre'
    | 'tutor'
    | 'auxiliar'
    | 'alumnos',
  severity: 'success' | 'error',
): SnackbarState => {
  const gender = entity.slice(-1) === 'a' ? 'a' : entity.slice(-1) === 's' ? entity.slice(-2) : 'o';
  const article = gender === 'a' ? 'La' : gender === 'os' ? 'los' : gender === 'as' ? 'las' : 'el';
  return {
    title:
      severity === 'success'
        ? `${entity.charAt(0).toLocaleUpperCase()}${entity.slice(1)} ${action.slice(
            0,
            -1,
          )}d${gender} exitosamente.`
        : `Error al ${action} ${entity}`,
    message:
      severity === 'success'
        ? `${article.charAt(0).toLocaleUpperCase()}${article.slice(
            1,
          )} ${entity} ha sido ${action.slice(0, -1)}d${gender} correctamente.`
        : `No se pudo ${action} ${
            article.slice(-1) === 's' ? '' : article
          } ${entity}. Por favor, verifica los datos e inténtalo de nuevo.`,
    severity: severity,
  };
};

export const contentSnackbarMessage = ({ errorType, message }: ContentErrorType): SnackbarState => {
  switch (errorType) {
    case 'CONTENT_NAME_EXISTS':
      message.message = 'Ya existe un módulo con este nombre, por favor elija otro.';
      break;
    case 'CONTENT_PUBLISH_EARLY':
      message.message = 'La fecha de publicación debe ser mayor a la fecha de inicio del curso.';
      break;
    case 'CONTENT_PUBLISH_LATE':
      message.message = 'La fecha de publicación debe ser inferior a la fecha de fin del curso.';
      break;
    case 'CONTENT_DEADLINE_EARLY':
      message.message = 'La última fecha de entrega debe ser mayor a la fecha de inicio del curso.';
      break;
    case 'CONTENT_DEADLINE_LATE':
      message.message = 'La última fecha entrega debe ser inferior a la fecha de fin del curso.';
      break;
    default:
      break;
  }

  return message;
};

export const moduleSnackbarMessage = ({ errorType, message }: ModuleErrorType): SnackbarState => {
  switch (errorType) {
    case 'MODULE_NAME_EXISTS':
      message.message = 'Ya existe un módulo con este nombre, por favor elija otro.';
      break;
    case 'MODULE_DATE_EARLY':
      message.message = 'La fecha de publicación debe ser mayor a la fecha de inicio del curso.';
      break;
    case 'MODULE_DATE_LATE':
      message.message = 'La fecha de publicación debe ser inferior a la fecha de fin del curso.';
      break;
    default:
      break;
  }

  return message;
};
