import React, { ReactElement } from 'react';
import { ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/default-layout';
import { themePlugin } from '@react-pdf-viewer/theme';

const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
  <Toolbar>
    {(slots: ToolbarSlot) => {
      const themePluginInstance = themePlugin();
      const { SwitchThemeButton } = themePluginInstance;
      const { Zoom, ZoomIn, ZoomOut } = slots;
      return (
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            width: '100%',
          }}
        >
          <div style={{ padding: '0px 2px' }}>
            <ZoomOut />
          </div>
          <div style={{ padding: '0px 2px' }}>
            <Zoom levels={[0.8, 1.2, 1.6]} />
          </div>
          <div style={{ padding: '0px 2px' }}>
            <ZoomIn />
          </div>
          <div>
            <SwitchThemeButton />
          </div>
        </div>
      );
    }}
  </Toolbar>
);

export default renderToolbar;
