import * as React from 'react';
import { Dialog, DialogContent, PaperProps } from '@mui/material';

import { PDFViewer, VideoPlayer } from 'src/components/shared/ui';
import { useAppDispatch, useAppSelector } from 'src/redux';
import { RootReducer } from 'src/redux/modules/types';
import { hideFileModal } from 'src/redux/modules/ui/actions';

const FileModal = () => {
  const { open, type, handleOnClose, url } = useAppSelector(
    (state: RootReducer) => state.ui.fileModal,
  );
  const dispatch = useAppDispatch();

  const handleClose = () => {
    handleOnClose?.();
    dispatch(hideFileModal());
  };
  return (
    <Dialog
      keepMounted
      onClose={handleClose}
      open={open}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={
        {
          sx: {
            height: type === 'pdf' ? '100%' : '75%',
            maxHeight: '90%',
            width: type === 'pdf' ? '60%' : '75%',
            maxWidth: '100%',
          },
          'data-testid': 'modal-container-div',
        } as PaperProps
      }
    >
      <DialogContent
        sx={{
          padding: '0px',
          overflow: 'hidden',
        }}
      >
        {url && type === 'video' ? <VideoPlayer link={url} /> : <PDFViewer url={url} />}
      </DialogContent>
    </Dialog>
  );
};

export default FileModal;
