import React from 'react';
import { TableRow } from '@mui/material';

import { Text } from 'src/components/shared/ui';
import { wrapName } from 'src/utils/formatters';

import { HeaderCellGroupProps } from '../../types';
import { StyledTableCell } from '../head';
import styles from './table-head.module.css';

const TableHeaderCellGroup = ({
  headCells,
  headCellGroups,
  cellContentLength,
  maxCellContentLength,
}: HeaderCellGroupProps) => {
  const mainHeaderCellsCount = headCellGroups
    ? headCellGroups.reduce((count, headCellGroup) => count + headCellGroup.columns, 0)
    : 0;
  return (
    <TableRow className={styles.tableRow}>
      {headCellGroups.map((headCellGroup, index) => (
        <StyledTableCell
          key={index}
          align={headCellGroup?.align ? headCellGroup.align : 'center'}
          colSpan={headCellGroup?.columns}
          className={
            headCellGroup?.border
              ? styles.tableHeadCellGroupsWithBorder
              : styles.tableHeadCellGroups
          }
          sx={{ borderRight: 2, borderColor: 'white' }}
        >
          <Text variant="body1" color={'white'} fontWeight={'800'}>
            {wrapName(headCellGroup?.headerName, cellContentLength, maxCellContentLength)}
          </Text>
        </StyledTableCell>
      ))}
      {mainHeaderCellsCount < headCells.length + 2 && (
        <StyledTableCell colSpan={headCells.length - mainHeaderCellsCount + 2} />
      )}
    </TableRow>
  );
};
export default TableHeaderCellGroup;
