import { Mark } from 'src/interfaces/entities/content';
import { CourseStatus } from 'src/interfaces/entities/course';
import { RoleType } from 'src/interfaces/entities/course-user';
import { AdmissionResult } from 'src/interfaces/entities/postulant-course';
import { Report } from 'src/interfaces/entities/report';
import { UserType } from 'src/redux/modules/auth/types';

export const capitalizeFirstLetter = (text: string) => {
  if (text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return '';
};

export const setStateTextColor = (courseStatus: CourseStatus) => {
  switch (courseStatus) {
    case 'SOON':
      return { text: 'Próximo', color: 'soon.main' };
    case 'OPEN_INSCRIPTION':
      return { text: 'Inscripciones abiertas', color: 'inscription.main' };
    case 'IN_SELECTION':
      return { text: 'En selección', color: 'in_selection.main' };
    case 'IN_PROGRESS':
      return { text: 'En curso', color: 'in_progress.main' };
    case 'COMPLETED':
      return { text: 'Completado', color: 'completed.main' };
    case 'INACTIVE':
      return { text: 'Inactivo', color: 'inactive.main' };
  }
};

export const getRoleLabel = (role: RoleType, type: UserType) => {
  if (type === 'NORMAL') {
    switch (role) {
      case 'ADMIN':
        return 'Administrador';
      case 'TUTOR':
        return 'Tutor';
      case 'AUXILIARY':
        return 'Auxiliar';
      default:
        return 'Alumno';
    }
  }
  return 'Super admin';
};

export const convertPostulantCourses = (data, views) => {
  return data
    ?.reduce((prev = [], obj, index) => {
      const {
        _id,
        postulant: { _id: postulantId, lastName, firstName, email, age, country },
      } = obj;
      const view = views?.find((v) => v._id == obj.view)?.name;
      const admissionInfo = obj.admissionResults.reduce((acc = {}, admRe: AdmissionResult) => {
        return {
          ...acc,
          [admRe.admissionTest.name]: { score: admRe.score, admissionResult: admRe._id },
        };
      }, {});
      prev[index] = {
        _id,
        postulantId,
        firstName,
        lastName,
        country,
        age,
        email,
        view,
        ...admissionInfo,
      };
      return prev;
    }, [])
    .sort((a: { firstName: string }, b: { firstName: string }) =>
      a.firstName.localeCompare(b.firstName),
    );
};

export const convertModuleReportsNew = (data) => {
  return data?.reduce((prev = [], obj: Report, index) => {
    const { _id, courseUser, assistance } = obj;
    const reportInfo = obj.contentAnswer.reduce((acc = {}, contentAnswer) => {
      if (!contentAnswer.content) return acc;
      const marks = contentAnswer.content.marks.reduce((acc = {}, mark: Mark) => {
        const reportedMarkById = contentAnswer.reportMarks.find((obj) => obj.mark === mark._id);
        return {
          ...acc,
          [mark.name]: reportedMarkById?.grade.toString(),
        };
      }, {});
      return {
        ...acc,
        ...marks,
      };
    }, {});

    if (typeof courseUser === 'object') {
      prev[index] = {
        _id,
        firstName: courseUser.user.postulant.firstName,
        lastName: courseUser.user.postulant.lastName,
        assistance,
        ...reportInfo,
      };
    }
    return prev;
  }, []);
};

export const convertDatatoNotes = (data, admissionTests) => {
  const scores = admissionTests?.reduce(
    (prev = [{}], testName, index) => {
      prev[index] = {
        admissionResult: data.row[testName].admissionResult,
        score: Number(data[testName]),
      };
      return prev;
    },
    [{}],
  );
  return { postulantId: data.row.postulantId, scores };
};

export const convertRoleToRoute = (role: RoleType, courseId: string) => {
  switch (role) {
    case 'ADMIN':
      return `/admin/course/${courseId}`;
    case 'TUTOR':
      return `/tutor/course/${courseId}`;
    case 'AUXILIARY':
      return `/auxiliary/course/${courseId}`;
    default:
      return `/student/course/${courseId}/modules`;
  }
};

export const wrapName = (rowName: string, length: number, maxLength: number) => {
  const table = document?.getElementsByClassName('MuiTable-root css-11u0tft-MuiTable-root');
  if (
    (table[0]?.querySelector('tr').cells.length > 7 && rowName?.length > 35) ||
    rowName?.length > maxLength
  ) {
    return rowName?.slice(0, length) + '...';
  } else {
    return rowName;
  }
};
